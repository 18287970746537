import React, { useEffect, useState } from "react";
import { Flex, Text, Spinner } from "@chakra-ui/react";
import { JsonToTable } from "react-json-to-table";
import Progress from "react-progressbar";
import { Heading } from '@chakra-ui/react'

const TableSection = ({
  tableData,
  progressBar,
  displayOn,
  isClear,
  downloadStatementFilter,
  tableName
}) => {
  const messages = [
    "Converting PDF into Images ... ✨",
    "Converting Images into Imagebytes ... 👓",
    "Extracting data from Images 1... 🎡",
    "Extracting data from Images 2... 🎡",
    "Extracting data from Images 3... 🎡",
    "Extracting data from Images 4... 🎡",
    "Extracting data from Images 5... 🎡",
    "Extracting data from Images 6... 🎡",
    "Identifing table names ... ⚓",
    "Validating table data ... 🎲",
    "Structuring tables ... 🧩",
    "Extraction will be ready in few seconds ... 🚀",
    "Trying our best to serve you better ... 🚀",
    "Extraction will be ready in few seconds please hold on ... 🚀",
  ];
  const [message, setMessage] = useState(messages[0]);

  useEffect(() => {
    if (displayOn) {
      setMessage(messages[0]);
      let timeout = 5000;
      messages.map((message) => {
        setTimeout(() => setMessage(message), timeout);
        timeout += 5000;
      });
    }
  }, [displayOn]);

  return (
    <>
      <Flex flexDirection="column" marginBottom="2rem">
        <Heading marginBottom="1rem">{tableName}</Heading>
        {isClear && (
          <Text
            fontSize="18px"
            fontWeight="bold"
            marginTop="20px"
            alignSelf="center"
            color="#0FABE0"
          >
            Upload document to view extraction!
          </Text>
        )}
        {displayOn && (
          <Flex
            flexDirection="column"
            alignSelf="center"
            fontWeight="bold"
            width="70%"
          >
            <Text fontSize="16px" alignSelf="center" fontWeight="medium">
              File upload {progressBar}% completed!
            </Text>
            <Progress completed={progressBar} />
            {progressBar > 99 && (
              <Flex
                marginTop="2rem"
                flexDirection="column"
                alignItems="center"
                alignSelf="center"
                alignContent="center"
              >
                <Spinner
                  thickness="5px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="#0FABE0"
                  size="xl"
                />
                <Text
                  fontSize="18px"
                  fontWeight="bold"
                  marginTop="0.5rem"
                  alignSelf="center"
                  color="#0FABE0"
                >
                  {message}
                </Text>
              </Flex>
            )}
          </Flex>
        )}
        {!displayOn && !isClear && downloadStatementFilter && (
          <JsonToTable json={tableData} />
        )}
      </Flex>
    </>
  );
};

export default TableSection;
