import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Dashboard from "./views/Dashboard";
import { ChakraProvider } from "@chakra-ui/react";
import { UserAuth } from "./context/UserAuth";
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider>
    <UserAuth>
      <Dashboard />
    </UserAuth>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
