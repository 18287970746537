import React, { useContext, useState, useEffect } from "react";
import ethanai_logo from "../../assets/ethanai_logo.png";
import {api} from "../Dashboard/index";
import AppContext from "../../context/UserAuth";
import eye from "../../assets/eye.svg";




const LoginForm = () => {
  const { loggedStatus, setLoggedStatus } = useContext(AppContext);
  const [showOTP, setShowOTP] = useState(false);

  return (
    <div id="loginform">
      <FormHeader title="Refinery | Ethan-AI" />
      {showOTP ? (
        <OTPScreen/>
      ) : (
        <Form onSubmit={()=> setShowOTP(true)}/>
      )}
    </div>
  );
};

const OTPScreen = ( ) => {
  const [otp, setOTP] = useState("");
  const {userId,setLoggedStatus}= useContext(AppContext);
  const [progressBar, setProgressBar] = useState();
  const [loggedStatusResp, setLoggedStatusResp] = useState("Log in");

  const handleOTPSubmit = async (event) => {
    event.preventDefault();


    try {
      const formData = new FormData();
      formData.append("otp", otp);
      formData.append("user_id", userId);

      const config = {
        onUploadProgress: (progressEvent) => {
          let { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setProgressBar(percent);
        },
      };
      api
        .post(`https://auth-server-prod.ethan-ai.com/api/verify-otp`, formData, config)
        .then((res) => {
          if (
            res.status === 200
            ) {
            setLoggedStatus(true);
            localStorage.setItem("adds",`Bearer ${res.data.access_token}`)
            localStorage.setItem("continueadds",res.data.refresh_token)
          } 
        })
        .catch((err) => {
          setLoggedStatusResp("Invalid Credentials")
          setTimeout(() => setLoggedStatusResp("Log in") , 3000);
        });
      
  }catch{
    setLoggedStatusResp("Invalid Credentials")
    setTimeout(() => setLoggedStatusResp("Log in") , 3000);
  }

}

  return (
    <div>
      <h2>Enter OTP</h2>
      <form onSubmit={handleOTPSubmit}>
        <div className="row">
          <label>OTP</label>
          <input
            required
            type="text"
            id="otp"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOTP(e.target.value)}
          />
        </div>
        <div className="row">
          <button type="submit">Verify OTP</button>
        </div>
      </form>
    </div>
  );
}

const FormHeader = (props) => (
  <>
    <img src={ethanai_logo} style={{ margin: "auto" }} />
    <section>
      <div className="container">
        <h1 data-text={props.title} style={{ marginTop: "10%" }}>
          {props.title}
        </h1>
      </div>
    </section>
  </>
);

const Form = ({onSubmit}) => {
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [progressBar, setProgressBar] = useState();
  const [loggedStatusResp, setLoggedStatusResp] = useState("Log in");
  const { loggedStatus, setLoggedStatus, userId, setUserId } = useContext(AppContext);
  const [type,setType]=useState(true);
  useEffect(() => {
      validateLogin();

  },[]);

  const validateLogin = () => {

    try {
      if (localStorage.getItem("continueadds") !== null){

      const formData = new FormData();
      formData.append("refresh", localStorage.getItem("continueadds"));

      const config = {
        onUploadProgress: (progressEvent) => {
          let { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setProgressBar(percent);
        },
      };
      api
        .post(`https://auth-server-prod.ethan-ai.com/api/token/refresh/`, formData, config)
        .then((res) => {
          if (
            res.status === 200
            ) {
            localStorage.setItem("adds",`Bearer ${res.data.access}`)
            setLoggedStatus(true);
          } 
        })
        .catch((err) => {

          const formData = new FormData();
          formData.append("refresh", localStorage.getItem("continueadds"));

          api.post(`/api/token/refresh/`, formData, config).then((res) => {
              if (res.status === 200 ) {
                setLoggedStatus(true);
                localStorage.setItem("adds",res.data.access)
              } 
            })
            .catch((err) => {
              setLoggedStatus(false);
            });



        });

      } 
    } catch (e) {
      setLoggedStatusResp("Log in")
    }

  }


  const handleSubmitButton = async (event) => {
    event.preventDefault()

    try {
      const formData = new FormData();
      formData.append("username", userName);
      formData.append("password", password);

      const config = {
        onUploadProgress: (progressEvent) => {
          let { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setProgressBar(percent);
        },
      };
      // api
      //   .post(`/api/token/`, formData, config)
      //   .then((res) => {
      //     if (
      //       res.status === 200
      //       ) {
      //       setLoggedStatus(true);
      //       localStorage.setItem("adds",res.data.access)
      //       localStorage.setItem("continueadds",res.data.refresh)
      //     } 
      //   })
      //   .catch((err) => {
      //     setLoggedStatusResp("Invalid Credentials")
      //     setTimeout(() => setLoggedStatusResp("Log in") , 3000);
      //   });
            api
        .post(`https://auth-server-prod.ethan-ai.com/api/login`, formData, config)
        .then((res) => {
          if (
            res.status === 200
            ) {
            // setLoggedStatus(true);
            setUserId(res.data.user_id)
            onSubmit()
            localStorage.setItem("adds",res.data.access)
            localStorage.setItem("continueadds",res.data.refresh)
          } 
        })
        .catch((err) => {
          setLoggedStatusResp("Invalid Credentials")
          setTimeout(() => setLoggedStatusResp("Log in") , 3000);
        });
    } catch (e) {
      setLoggedStatusResp("Log in")
    }


  };

  return (
    <form onSubmit={handleSubmitButton}>
      <FormInput
        description="Username"
        id="username"
        placeholder="Enter your username"
        type="text"
        
        onChange={setUsername}
      />
      <div className="password-container" style={{ position:"relative"}}>
      <FormInput
        description="Password"
        id="password"
        placeholder="Enter your password"
        type={type ? "password" : "text"}
        onChange={setPassword}
        />
      <img className="eye-image" onClick={()=>setType((prev)=> !prev)} src={eye} alt="hi" style={{position:"absolute",top:"60%",right:"15%"}}/>
      </div>
      <FormButton
        type="submit"
        title={loggedStatusResp}
      />
    </form>
  );
};

const FormButton = (props) => (
  <div id="button" className="row">
    <button type={props.type} >
      {props.title}
    </button>
  </div>
);

const FormInput = (props) => (
  <div className="row">
    <label>{props.description}</label>
    <input
      required
      type={props.type}
      id={props.id}
      placeholder={props.placeholder}
      onChange={(e) => props.onChange(e.target.value)}
    />
  </div>
);

export default LoginForm;
