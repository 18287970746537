import React, { useContext, useState, useEffect } from "react";
// import fs from 'fs';

import json2csv,{parse} from 'json2csv';
import {
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import Select from "react-select";
import Header from "./Components/header";
import Upload_Section from "./Components/upload_section";
import TableSection from "./Components/table";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import data from "../../assets/demo_table.json";
import * as XLSX from "xlsx";
import axios from "axios";
import "animate.css";
import MySelect from "./Components/dropdownCustomization";
import LoginForm from "./login";
import AppContext from "../../context/UserAuth";

var filedownload = require('js-file-download');

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:8000",
});

export default function Dashboard() {
  const [uploadApiData, setUploadApiData] = useState([]);
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [progressBar, setProgressBar] = useState(0);
  const [tableDataJson, setTableDataJson] = useState([]);
  const [displayOn, setDisplayOn] = useState(false);
  const [isClear, setIsClear] = useState(true);
  const [downloadStatementFilter, setDownloadStatementFilter] =
    useState("Select...");
    const [downloadStatementSelectedOption, setDownloadStatementSelectedOption] =
    useState();
    const [isProcessingDownload, setIsProcessingDownload] = useState(false);
    const [statementTableArrayData, setStatementTableArrayData] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
  const [checked, setChecked] = useState({});
  const [openModel,setOpenModal]=useState(false);
  const { isOpen ={openModel}, onOpen, onClose={openModel} } = useDisclosure();
  const { loggedStatus, setLoggedStatus } = useContext(AppContext);
  const [dbButton, setDBButton] = useState(null);
  const [isActionShow, setIsActionShow] = useState(false)

  const [allChecked, setAllChecked] = useState(false);
  const [positionChecked, setPositionChecked] = useState(false);
  const [transactionChecked, setTransactionChecked] = useState(false);
  const [firstArray, secondArray] = uploadApiData

  // useEffect(() => {
  //   let allChecked = true;
  //   for (const inputName in checked) {
  //     if (checked[inputName] === false) {
  //       allChecked = false;
  //     }
  //   }
  //   if (allChecked) {
  //     setCheckedAll(true);
  //   } else {
  //     setCheckedAll(false);
  //   }
  // }, [checked]); 

  // useEffect(() => {
  //   if (statementTableArrayData.length > 0) {
  //     setChecked((prevState) => {
  //       const newState = { ...prevState };
  //       statementTableArrayData.forEach((e) => {
  //         newState[e] = false;
  //       });
  //       return newState;
  //     });
  //     onOpen();
  //   }
  // }, [statementTableArrayData]);

  // const Option = (props) => {
  //   return (
  //     <div>
  //       <components.Option {...props}>
  //         <input
  //           type="checkbox"
  //           checked={props.isSelected}
  //           onChange={() => null}
  //         />{" "}
  //         <label>{props.label}</label>
  //       </components.Option>
  //     </div>
  //   );
  // };

  // const allOption = {
  //   label: "Select all",
  //   value: "*",
  // };

  // const ValueContainer = ({ children, ...props }) => {
  //   const currentValues = props.getValue();
  //   let toBeRendered = children;
  //   if (currentValues.some((val) => val.value === allOption.value)) {
  //     toBeRendered = [[children[0][0]], children[1]];
  //   }

  //   return (
  //     <components.ValueContainer {...props}>
  //       {toBeRendered}
  //     </components.ValueContainer>
  //   );
  // };

  // const MultiValue = (props) => {
  //   let labelToBeDisplayed = `${props.data.label}, `;
  //   if (props.data.value === allOption.value) {
  //     labelToBeDisplayed = "All is selected";
  //   }
  //   return (
  //     <components.MultiValue {...props}>
  //       <span>{labelToBeDisplayed}</span>
  //     </components.MultiValue>
  //   );
  // };

  // const animatedComponents = makeAnimated();

  // useEffect(() => {
  //   const handleScroll = () => {
  //     let moving = window.pageYOffset + 10;
  //     setVisible(position > moving);
  //     setPosition(moving);
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // });

  // const downloadExtractedFile = () => {
  //   setIsProcessingDownload(true);
   
  //   const workbook = XLSX.utils.book_new();
  //   tableDataJson.forEach((element) => {
  //     // Excel sheet name max character limit is 31
  //     let sheetName = element["Table Name"].substring(0, 30);
  //     const worksheet = XLSX.utils.json_to_sheet(element.Data);
  //     XLSX.utils.book_append_sheet(workbook, worksheet, sheetName, true);
  //   });

  //   // Change name of excel sheet from here
  //   XLSX.writeFile(workbook, "Extraction.xlsx");
  //   setIsProcessingDownload(false);
  // };



  // const downloadcsv = (saparator) => {
  //   setIsProcessingDownload(true);

  //   tableDataJson.forEach((e)=>{
  //     let columns_data = Object.keys(e.Data[0])
  //     const csvdata = parse(e.Data, {fields: columns_data,delimiter:saparator});
  //     filedownload( csvdata,`${e["Table Name"]}.csv`);
  //   })

  //   setIsProcessingDownload(false);
  // };

  // const toggleCheck = (inputName) => {
  //   setChecked((prevState) => {
  //     const newState = { ...prevState };
  //     newState[inputName] = !prevState[inputName];
  //     return newState;
  //   });
    
  // };

  // const selectAll = (value) => {
  //   setCheckedAll(value);
  //   setChecked((prevState) => {
  //     const newState = { ...prevState };
  //     for (const inputName in newState) {
  //       newState[inputName] = value;
  //     }
  //     return newState;
  //   });
  // };

  const getOnlyTableNameArray = (data) => {
    let res = [];
    data.forEach((e) => {
      res.push(e["Table Name"]);
    });
    setStatementTableArrayData(res);
  };

  const getTableNames = () => {
    let res = [];
    uploadApiData.forEach((e) => {
      if (e["Table Name"] in checked && checked[e["Table Name"]] == true) {
        let obj = {
          value: e["Table Name"],
          label: e["Table Name"],
        };
        res.push(obj);
      }
    });
    setTableData(res);
    setDownloadStatementFilter(res);
  };

  const handleModel = () => {
    getTableNames();
    setOpenModal(false);
    setIsActionShow(true)
  };

  const setTableData = (data) => {
    let temp = [];
    data.forEach((e) => {
      temp.push(e.label);
    });
    let newData = [];
    uploadApiData.forEach((e) => {
      if (temp.includes(e["Table Name"])) {
        newData.push(e);
      }
    });
    setTableDataJson(newData);
  };

  const handleUploadButton = async (
    custodianName,
    selectedFile,
    statementId,
    setIsProcessingUpload,
    databaseFileStatus,
    selectedClient,
    custodianId,
    statement_date,
    relationship_number

  ) => {
    // If activeTab is 0 -> From S3
    // If activeTab is 1 -> Local Upload
    setIsClear(false);
    setDisplayOn(true);
    setIsProcessingUpload(true);
    try {
      console.log(custodianName,selectedFile,"hello")
      const formData = new FormData();
      formData.append("bankname", custodianName);
      formData.append("type", "json");
      formData.append("categorize", "false");
      formData.append("filename", selectedFile);
      formData.append("token",localStorage.getItem("adds"))
      formData.append("stament_Id",statementId)
      formData.append("status", databaseFileStatus)
      formData.append("client", selectedClient)
      formData.append("custodianId", custodianId)
      formData.append("statement_date",statement_date)
      formData.append("relationship_number",relationship_number)
      // statementId
      // console.log(`Selected Client ${selectedClient}`)
      // console.log(`Selected Custodian ${custodianId}`)

      console.log("Status during upload", databaseFileStatus)

      const config = {
        onUploadProgress: (progressEvent) => {
          let { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setProgressBar(percent);
        },
      };
      api.post(`api/Extract/`, formData, config)
        .then((res) => {
          if (
            res.data.Data === "Unable to extract data , please try again..."
          ) {
            alert("Unable to extract data , please try again...");
          } else {
            setUploadApiData(res.data.Data);
            getOnlyTableNameArray(res.data.Data);
          }
          setDisplayOn(false);
          setIsProcessingUpload(false);
        }).then(()=> setOpenModal(true))
        .catch((err) => {
          alert("Please try Again")
          setIsProcessingUpload(false);
        });
    } catch (e) {
        alert(e)
    }
  };

  const handleButtonClick = (actionName) => {
    setDBButton(actionName);
    switch (actionName) {
      case 'Refinery':
        console.log('Push to Refinery clicked');
        break;
      default:
        break;
    }
  };

  // const handlePushRefinary = async () => {
  //   try {
  //     console.log(custodianName, selectedFile, "hello");
  //     const formData = new FormData();
  //     formData.append("bankname", custodianName);
  
  //     api.post(`api/Extract/`, formData)
  //       .then((res) => {
  //         if (res.data.Data === "Unable to extract data , please try again...") {
  //           alert("Unable to extract data , please try again...");
  //         } else {
  //           setUploadApiData(res.data.Data);
  //           getOnlyTableNameArray(res.data.Data);
  //         }
  //       })
  //       .catch((err) => {
  //         console.error("Error:", err);
  //         alert("Please try Again");
  //       });
  //   } catch (e) {
  //     console.error("Exception:", e);
  //     alert("Please try Again");
  //   }
  // };
  

  const renderTableSection = (tableData, tableName) => {
      return (
        <TableSection
          tableData={tableData}
          displayOn={displayOn}
          progressBar={progressBar}
          downloadStatementFilter={downloadStatementFilter}
          tableName={tableName}
        />
      );
  };
  
  const handleCheckboxChange = (checkboxName) => {
    if (checkboxName === "all") {
      setAllChecked(!allChecked);
      setPositionChecked(true);
      setTransactionChecked(true);
    } else if (checkboxName === "position") {
      setPositionChecked(!positionChecked);
      setAllChecked(!allChecked);
    } else if (checkboxName === "transaction") {
      setTransactionChecked(!transactionChecked);
      setAllChecked(!allChecked);
    } 
  };

  const downloadCSV = (jsonData, fileName) => {
    const csv = json2csv.parse(jsonData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName + '.csv';
    link.click();
};

  const downloadXLSX = (jsonData) => {
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, 'output.xlsx');
  };

  const downloadBothTablesCSV = () => {
    const [firstArray, secondArray] = uploadApiData;

    downloadCSV(firstArray, 'positionTable');
    downloadCSV(secondArray, 'transactionTable');
};

  const cls = visible ? "visible" : "hidden";
  if (loggedStatus === true) {
    return (
      <Flex flexDirection="column" margin="10px">
        <Modal
          blockScrollOnMount={false}
          isOpen={openModel}
          onClose={() => setOpenModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Extraction is ready!</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setIsActionShow(false);
              }}
            />
            <ModalBody>
              <Text fontWeight="bold" mb="1rem">
                Choose the table names to continue...
              </Text>
              <Flex flexDirection="column">
                <Flex flexDirection="row" gap="1rem">
                  <input
                    type="checkbox"
                    checked={allChecked}
                    onChange={() => handleCheckboxChange("all")}
                  />
                  <label>All</label>
                </Flex>
                <Flex flexDirection="row" gap="1rem">
                  <input
                    type="checkbox"
                    checked={positionChecked}
                    onChange={() => handleCheckboxChange("position")}
                  />
                  <label>Position</label>
                </Flex>
                <Flex flexDirection="row" gap="1rem">
                  <input
                    type="checkbox"
                    checked={transactionChecked}
                    onChange={() => handleCheckboxChange("transaction")}
                  />
                  <label>Transaction</label>
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" onClick={() => handleModel()}>
                Continue
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Flex flexDirection="column" gap="1rem">
          <Flex flexDirection="column" className={`sticky-header ${cls}`}>
            <Header />
            <Upload_Section handleUploadButton={handleUploadButton} />
          </Flex>
        </Flex>
        <Flex flexDirection="column" className="table-flex">
          <Flex flexDirection="column" marginBottom="1rem">
            <Text
              fontSize="18px"
              fontWeight="bold"
              position="sticky"
              top="0px"
              backgroundColor="white"
              paddingTop="5px"
              paddingBottom="5px"
            >
              Extracted <span style={{ color: "#0FABE0" }}>Data</span>
            </Text>
            {/* {!displayOn && !isClear && ( */}
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              gap="1rem"
              width="100%"
            >
              {/* <div style={{ width: "25rem" }}> */}
              {/* <MySelect
                    placeholder={"All"}
                    required
                    isMulti
                    components={{
                      Option,
                      MultiValue,
                      ValueContainer,
                      animatedComponents,
                    }}
                    allowSelectAll={true}
                    name="statmentFilter"
                    isSearchable={true}
                    // noOptionsMessage="No tables are extracted!"
                    id="statmentFilter"
                    value={downloadStatementSelectedOption}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    style={{ border: "0.4px solid #D1D0D0" }}
                    options={downloadStatementFilter}
                    onChange={(e) => setDownloadStatementSelectedOption(e)}
                  /> */}
              {/* </div> */}

              {/* <Button
                  isLoading={isProcessingDownload}
                  loadingText="Downloading"
                  colorScheme="green"
                  variant="solid"
                  onClick={() => downloadExtractedFile()}
                  // paddingRight="1rem"
                >
                  <Text fontSize="1rem" fontWeight="500">
                    Download
                  </Text>
                </Button> */}

              <Flex flexDirection="row" gap="1rem">
                <Menu>
                  <MenuButton
                  isLoading={isProcessingDownload}
                  loadingText="Downloading"
                  colorScheme="green"
                  variant="solid"
                  as={Button}
                  >
                    Download
                  </MenuButton>
                  <MenuList>
                    {/* <MenuItem onClick={() => downloadXLSX(combinedData)}>xlsx</MenuItem>
                    <MenuItem onClick={() => downloadCSV(combinedData)}>CSV</MenuItem> */}
                    <MenuItem onClick={downloadBothTablesCSV}>Download as CSV</MenuItem>
                  </MenuList>
                </Menu>
                <Menu>
                  <MenuButton
                    isLoading={isProcessingDownload}
                    loadingText="Downloading"
                    colorScheme="green"
                    variant="solid"
                    as={Button}
                    onClick={() => setOpenModal(true)}
                  >
                    Preview
                  </MenuButton>
                </Menu>
              </Flex>

              {isActionShow && (
                <Flex flexDirection="row" gap="1rem">
                  <Button
                    colorScheme="red"
                    onClick={() => handleButtonClick("Refinery")}
                  >
                    Push to Refinery
                  </Button>
                  {/* <Button
                    colorScheme="green"
                    onClick={() => handleButtonClick("Database")}
                  >
                    Push to Database
                  </Button> */}
                </Flex>
              )}
            </Flex>
            {/* )} */}
          </Flex>

          {positionChecked && renderTableSection(firstArray, "Position Data")}
          {transactionChecked && renderTableSection(secondArray, "Transaction Data")}
        </Flex>
      </Flex>
    );
  } else {
    return <LoginForm />;
  }
}