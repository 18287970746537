import React ,{useEffect,useState, useContext} from "react";
import { Flex, Text, Button } from "@chakra-ui/react";
import ethanai_logo from "../../../assets/ethanai_logo.png";
import "animate.css";
import axios from "axios";
import AppContext from "../../../context/UserAuth";
import { api } from "..";

const Header = () => {
  const { setLoggedStatus } = useContext(AppContext);


  const logOut = async (event) => {

    try {
      localStorage.removeItem("adds")
      localStorage.removeItem("continueadds")
      setLoggedStatus(false)
    } catch (e) {
        alert("Unable to Logout Contact someone from Tech team")
    }

  }




  return (
    <Flex
      justifyContent="space-between"
      width="100%"
      alignItems="center"
      alignSelf="center"
      alignContent="center"
      padding="1rem"
      className="animate__backInDown"
    >
      <Text fontSize="28px" fontWeight="bolder"  >
        Refinery
      </Text>


      <Flex >
      <Button
        colorScheme="red"
        variant="solid"
        paddingRight="1rem"
        margin="auto"
        onClick={logOut}
      >
        <Text fontSize="1rem" fontWeight="500" >
          Logout
        </Text>
      </Button>

      <Flex paddingLeft="2rem">
        <img src={ethanai_logo} width="90%" />
      </Flex>
      </Flex>


    </Flex>
  );
};

export default Header;
