import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Flex,
  Text,
  Tabs,
  TabList,
  Tab,
  Tooltip,
  Button,
} from "@chakra-ui/react";
import Select from "react-select";
import { api } from "../index";
// import from_s3_logo from "../../../assets/from_s3_logo.png";
import upload_logo from "../../../assets/upload_logo.png";

const Upload_Section = ({ handleUploadButton }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
  const [processingUpload, setProcessingUpload] = useState(false);
  const [isProcessingDelete, setIsProcessingDelete] = useState(false);
  const [custodianName, setCustodianName] = useState();
  const [statementName, setStatementName] = useState([]);
  const [selectedClient, setSelectedClient] = useState();
  const [clientDropDown, setClientDropDown] = useState([]);
  const [custodianDropdownData, setCustodianDropdownData] = useState([])
  const [fileName, setFileName] = useState([])
  const [monthDrop, setMonthDrop] = useState([])
  const [typeOfStatememt, setTypeOfStatememt] = useState("All");
  const [fileStatus, setFileStatus] = useState({});
  const [month, setMonth] = useState();
  const [statementId,setStatementId]=useState()
  const [databaseFileStatus, setDatabaseFileStatus] = useState("");
  

  // const [selectedFile,setSelectedFile]=useState();

  const type_of_statment_dropdown_data = [
    { value: "All", label: "All" },
    { value: "Transaction", label: "Transaction" },
    { value: "Position", label: "Position" },
  ];

  const handleUploadOption = async (id) => {

    let payload = {};

    if (id === 0) {
      const res = await api.get(`https://transaction.ethan-ai.com/api/client`, {
        headers: {
          "Authorization": localStorage.getItem("adds")
        }
      })
      setClientDropDown(res.data)
      payload = {}
    }
    else if (id === 1) {
      const res = await api.get(`https://transaction.ethan-ai.com/api/client`, {
        headers: {
          "Authorization": localStorage.getItem("adds")
        }
      })
      let tempData = res.data.filter((data) => data.id === selectedClient?.value);
      setCustodianDropdownData(tempData[0]?.custodians)
      payload = {}
    }
    else if (id === 2) {
      const res = await api.get(`https://transaction.ethan-ai.com/api/statement/bank`, {
        params: {
          client: selectedClient.value,
          custodian: custodianName.value,
          page_size: 1000
        },
        headers: {
          "Authorization": localStorage.getItem("adds")
        }

      }
      )
      setMonthDrop(res?.data?.results)
      payload = {}
    }

    else if (id === 3) {
      const res = await api.get(`https://transaction.ethan-ai.com/api/statement/bank`, {
        params: {
          client: selectedClient.value,
          custodian: custodianName.value,
          statement_date: month.value,
        },
        headers: {
          "Authorization": localStorage.getItem("adds")
        }

      }
      )

      const fileList = res?.data?.results.map((file) => {
        const processFileName = file.s3_url.split('/').slice(-3).join('/');
        return processFileName 
      })
      
      setMonthDrop(res?.data?.results)
      setFileName(fileList)
      payload = {}
    }
  };

  const updateFileStatus = (event) => {
    if (event.target.files.length === 0) {
      setFileStatus({ "status": false })
    } else {
      setSelectedFile(event.target.files[0]);
      setFileStatus({ "name": event.target.files[0].name, "status": true })
    }
  }
  const processDocument = async (custodianName, selectedFile) => {
    if (selectedFile === undefined) {
      alert("PLease select a file to delete...");
      return;
    } else if (custodianName === "") {
      alert("PLease select a custodian...");
      return;
    }
    if (window.confirm(`Deleting file : ${selectedFile.name}`) === false) {
      return;
    }

    setIsProcessingDelete(true);
    api.delete(`/api/GetstatementList/${selectedFile.name}/${custodianName.value}/`).then((res) => {
      if (res.status == 200) {
        alert(res.data);
      }
      setIsProcessingDelete(false);
    });
  };

  return (
    <Flex
      justifyContent="space-around"
      gap="1rem"
      alignItems="center"
      alignSelf="center"
      alignContent="center"
      maxWidth="90%"
    >
      <Tabs
        variant="soft-rounded"
        colorscheme="blue"
        textColor="blue.800"
        size="sm"
        defaultIndex={0}
      >
        <TabList gap="1rem">
          <Tooltip label="Upload from local">
            <Tab
              tabIndex={2}
              color="#0FABE0"
              border="0.4px solid #C8E2FF"
              borderRadius="15px"
              flexWrap="wrap"
            >
              <Text fontSize="1rem" fontWeight="500">
                Upload
              </Text>
              <img src={upload_logo} height="auto" width="auto" />
            </Tab>
          </Tooltip>
        </TabList>
      </Tabs>
      <div style={{ width: "18rem" }}>
        <Select
          placeholder={"Client"}
          defaultValue={"client"}
          required
          onMenuOpen={() => handleUploadOption(0)}
          value={selectedClient}
          name="Client"
          id="client"
          style={{ border: "0.4px solid #D1D0D0" }}
          onChange={(e) => {
            setSelectedClient(e)
          }
          }
          options={clientDropDown.map((data) => (
            {
              value: data.id,
              label: data?.first_name || data?.name
            })
          )}
        />
      </div>
      <div style={{ width: "18rem" }}>
        <Select
          placeholder={"Custodian Name"}
          required
          onMenuOpen={() => handleUploadOption(1)}
          name="custodianName"
          id="custodianName"
          value={custodianName}
          style={{ border: "0.4px solid #D1D0D0" }}
          options={custodianDropdownData?.map((data) => (
            {
              value: data.id,
              label: data.name
            }
          ))}
          // options={clientDropDown}
          onChange={(e) => setCustodianName(e)}
          isDisabled={processingUpload}
        />

      </div>
      <div style={{ width: "18rem" }}>
        <Select
          placeholder={"Month"}
          onMenuOpen={() => handleUploadOption(2)}
          required
          name="statementName"
          id="statementName"
          style={{ border: "0.4px solid #D1D0D0" }}
          value={month}
          options={monthDrop.map((data) => (
            {
              value: data.statement_date,
              label: data.statement_date
            })
          )}
          onChange={(e) => setMonth(e)}
        />
      </div>

      <div style={{ width: "30em" }}>
        <Select
          placeholder={"File Name"}
          required
          onMenuOpen={() => handleUploadOption(3)}
          name="statementName"
          id="statementName"
          style={{ border: "0.4px solid #D1D0D0" }}
          value={selectedFile}
          options={fileName.map((data) => (
            {
              value: data,
              label: data,
            }
          )
          )}
          // onChange={(e) => 
          // {
          //   const data= monthDrop.filter((data)=>{
          //     if (data.s3_url.split('/').slice(-3).join('/') == e.value){
          //       setSelectedFile(e)
          //       return data
          //     }else{
          //       return null
          //     }
          //   }
          //   )
          //   setStatementId(data?.[0]?.id)
          // }}
          onChange={(e) => {
            const selectedValue = e.value;
            
            const matchingData = monthDrop.find((data) => {
              return data.s3_url.split('/').slice(-3).join('/') === selectedValue;
            });
            const fileFilter = monthDrop.find((file) => file.id == matchingData.id);
            
            if (matchingData) {
              setSelectedFile(e);
              setStatementId(matchingData.id);
              setDatabaseFileStatus(fileFilter.status)
            } 
          }}
          />
      </div>

      {/* {activeTab === 0 && (
        
        <div class="formbold-mb-5 formbold-file-input">
          <label for="file">
        <input type="file" name="file" id="file" accept="application/pdf" onChange={updateFileStatus} disabled={processingUpload} />
            <div style={{ display:"grid"}}>
              <span class="formbold-drop-file"> Drop or Select file here </span>
              <span class="formbold-browse"> {fileStatus.status ?  fileStatus.name : "Broswer" } </span>
            </div>
          </label>
        </div>
       ) } */}
      <div style={{ width: "18rem" }}>
        <Select
          // placeholder={"Type of data to extract"}
          placeholder={"All"}
          defaultValue={"All"}
          required
          name="typeOfStatment"
          id="typeOfStatment"
          value={typeOfStatememt}
          style={{ border: "0.4px solid #D1D0D0" }}
          options={type_of_statment_dropdown_data}
          onChange={(e) => setTypeOfStatememt(e)}
          isDisabled={true}
        // isDisabled={processingUpload} 
        />
      </div>
      <Button
        isLoading={processingUpload}
        isDisabled={isProcessingDelete ? true : false}
        loadingText="Processing"
        backgroundColor="#0FABE0"
        color="white"
        variant="solid"
        onClick={() => {
          if (custodianName === "" || selectedFile == undefined) {
            alert("Please enter values in all fields")
          }
          else {
            handleUploadButton(
              custodianName.label,
              selectedFile.value,
              statementId,
              setProcessingUpload,
              databaseFileStatus,
              selectedClient.value,
              custodianName.value,
            )
          }
        }
        }
      >
        <Text fontSize="1rem" fontWeight="500">
          Process
        </Text>
      </Button>
      <Button
        isDisabled={processingUpload ? true : false}
        isLoading={isProcessingDelete}
        loadingText="Deleting"
        colorScheme="red"
        variant="solid"
        onClick={() =>
          processDocument(custodianName, selectedFile)
        }
        paddingRight="1rem"
      >
        <Text fontSize="1rem" fontWeight="500">
          Delete
        </Text>
      </Button>
    </Flex>
  );
};

export default Upload_Section;
