import React, { createContext, useContext, useEffect, useState } from "react";

const AppContext = createContext();

export const UserAuth = ({ children }) => {
  const [loggedStatus, setLoggedStatus] = useState(false);
  const [userId,setUserId]=useState()
  const [statementId,setStatementId]=useState()

  return (
    <AppContext.Provider value={{ loggedStatus, setLoggedStatus,userId,setUserId }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
